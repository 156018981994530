import { render, staticRenderFns } from "./SurveyTable5.vue?vue&type=template&id=2f9b747e&scoped=true&"
import script from "./SurveyTable5.vue?vue&type=script&lang=js&"
export * from "./SurveyTable5.vue?vue&type=script&lang=js&"
import style0 from "./SurveyTable5.vue?vue&type=style&index=0&id=2f9b747e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f9b747e",
  null
  
)

export default component.exports