<template>
  <section class="dtc-main-section">
    <div class="dtc-grid-3">
      <h4 class="title mb-3" style="font-weight: bold;font-family: DFKai-sb;">
        輔導問卷調查統計
      </h4>
      <b-select
        :options="years"
        v-model="year"
        @change="getData"
        style="font-size:16px !important;margin-top:34px"
        class="d-print-none"
      ></b-select>
      <b-button
        size="sm"
        variant="success"
        class="mr-2 download-btn d-print-none"
        @click="downloadFile"
      >
        <i class="fas fa-file-download"></i>下載資料
      </b-button>
    </div>
    <b-tabs content-class="mt-1">
      <b-tab title="總表" active
        ><SurveyTablesAll
          :allData="allData"
          :totalPeople="totalPeople"
        ></SurveyTablesAll
      ></b-tab>
      <b-tab title="表一、受訪者狀態" active
        ><SurveyTable1
          :allData="allData"
          :totalPeople="totalPeople"
        ></SurveyTable1
      ></b-tab>
      <b-tab title="表二、在學中"
        ><SurveyTable2
          :allData="allData"
          :totalPeople="totalPeople"
        ></SurveyTable2
      ></b-tab>
      <b-tab title="表三、專業訓練中"
        ><SurveyTable3
          :allData="allData"
          :totalPeople="totalPeople"
        ></SurveyTable3
      ></b-tab>
      <b-tab title="表四、服務中"
        ><SurveyTable4
          :allData="allData"
          :totalPeople="totalPeople"
        ></SurveyTable4
      ></b-tab>
      <b-tab title="表五、服務期滿留任"
        ><SurveyTable5
          :allData="allData"
          :totalPeople="totalPeople"
        ></SurveyTable5
      ></b-tab>
      <b-tab title="表六、服務期滿離任"
        ><SurveyTable6
          :allData="allData"
          :totalPeople="totalPeople"
        ></SurveyTable6
      ></b-tab>
    </b-tabs>
  </section>
</template>

<script>
import queryString from "query-string";
import { store } from "@/store/global.js";
import SurveyTable1 from "./SurveyTable1.vue";
import SurveyTable2 from "./SurveyTable2.vue";
import SurveyTable3 from "./SurveyTable3.vue";
import SurveyTable4 from "./SurveyTable4.vue";
import SurveyTable5 from "./SurveyTable5.vue";
import SurveyTable6 from "./SurveyTable6.vue";
import SurveyTablesAll from "./SurveyTablesAll.vue";
const year = +new Date().getFullYear() - 1911;
const years = new Array(3).fill().map((s, i) => year - i);
const headers = [
  "表一、受訪者人口學變項與受訪者目前狀態",
  "表二、在學中受訪者的意見",
  "表三、專業訓練中受訪者的意見",
  "表四、服務中受訪者的意見",
  "表五、服務期滿留任受訪者的意見",
  "表六、服務期滿離任受訪者的意見",
];

export default {
  data() {
    return {
      items: [],
      allData: [],
      c0: [],
      headers,
      year,
      years,
      totalPeople: [],
    };
  },
  components: {
    SurveyTable1,
    SurveyTable2,
    SurveyTable3,
    SurveyTable4,
    SurveyTable5,
    SurveyTable6,
    SurveyTablesAll,
  },

  methods: {
    async downloadFile() {
      try {
        const {
          Message,
          Url,
        } = await axios.get(`LocalRecord/Report?year=${this.year}
        `);
        if (!Boolean(Message)) {
          window.open(Url);
        } else {
          this.$bvToast.toast(`無法下載`, {
            title: "查無資料",
            autoHideDelay: 5000,
            variant: "danger",
          });
        }
      } catch (e) {
        alert("" + e);
      }
    },
    async getData() {
      await window.axios
        .get(`LocalRecord/List?year=${this.year}`)
        .then((res) => {
          this.items = res.map((item) => ({
            category: item.Category,
            question: item.Question,
            discuss: "",
            options: item.AnswerNames.map((text, index) => ({
              text,
              percentage: item.AnswerRates[index]
                ? item.AnswerRates[index].toFixed(2)
                : 0,
              people: item.AnswerCount[index] ? item.AnswerCount[index] : 0,
            })),
            num: item.AnswerCount.reduce((acc, cur) => acc + +cur, 0),
          }));
        });
      if (this.items.length) {
        let num = this.items.length - 1;
        num = this.items[num].category ? +this.items[num].category : 1;

        let obj = {};
        this.c0 = this.items.filter((s) => !s.category);
        obj.category = this.c0;

        this.items = this.items.map((s, i) => ({
          category: this.items.filter((k) => k.category == i + 1),
        }));
        this.items.unshift(obj);
        this.items = this.items.slice(0, num + 1);

        this.items = this.items.map((s, i) => ({
          header: this.headers[i],
          category: s.category,
          maxNum: Math.max(...s.category.map((p) => p.num)),
          peopleNum: this.totalPeople[i],
        }));
      }
      this.allData = this.items;
    },
    async getPeopleNum() {
      try {
        const { Items, Count } = await window.axios.get(
          `LocalRecord/GetAnswerList?year=${this.year}`
        );

        const q1Num = Items.filter((s) => s.Question == 1).length;
        const q2Num = Items.filter((s) => s.Question == 2).length;
        const q3Num = Items.filter((s) => s.Question == 3).length;
        const q4Num = Items.filter((s) => s.Question == 4).length;
        const q5Num = Items.filter((s) => s.Question == 5).length;
        this.totalPeople = [Count, q1Num, q2Num, q3Num, q4Num, q5Num];
        this.getData();
      } catch (e) {
        alert(e);
      }
    },
  },

  mounted() {
    this.getPeopleNum();
  },
  created() {},
  watch: {
    year() {
      this.getPeopleNum();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  max-width: 1200px;
  margin: 0 auto;
  .border-left {
    border-color: #000 !important;
  }
}

.d-grid {
  display: grid;
}
.col-width-1 {
  width: 16%;
}
.col-width-2 {
  width: 69%;
}
.col-width-3 {
  width: 15%;
}

@for $i from 1 through 50 {
  .grid-col-#{$i} {
    grid-template-columns: repeat($i, 1fr);
  }
}

.dtc-5-column,
.dtc-3-column,
.dtc-6-column {
  display: grid;
  grid-template-columns: 225px repeat(3, 170px) 225px;
  border: 1px solid black;
  border-top: none;

  > div {
    padding: 5px;
    padding-left: 10px;
    text-align: left;
    border-right: 1px solid black;
    font-size: 16px;
  }
  > div:last-child {
    border: 0px solid black;
    font-size: 16px;
  }
  .item {
    margin-left: 34px;
  }
  .item-content {
    text-align: center;
  }
}

.dtc-3-column {
  grid-template-columns: 225px 510px 225px;
  .content {
    text-align: center;
    font-weight: 900;
  }
}
.dtc-6-column {
  grid-template-columns: 225px repeat(4, 127.5px) 225px;
}

.number-div {
  display: grid;
  grid-template-columns: 10px 1fr;
  gap: 1rem;
}

.header-dark {
  border: 1px solid black;
  background: #646b74;
  color: #fff;
}
.dtc-grid-3 {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  // justify-content: center;
  grid-gap: 12px;
}

.download-btn {
  position: fixed;
  top: 139px;
  right: 191px;
  z-index: 99999;
  height: 40px;
}
.title {
  margin-top: 40px;
}

@media print {
  @page {
    size: A4 portrait;
  }
  .dtc-main-section {
    width: 980px;
  }
  .header-dark {
    background: #fff;
    color: black;
  }

  .title {
    margin-top: 0px;
  }
  // ::v-deep #__BVID__13__BV_tab_controls_ {
  //   display: none;
  // }
  ::v-deep ul.nav.nav-tabs {
    display: none;
  }
}
</style>
